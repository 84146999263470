import type { GetServerSideProps, NextPage } from "next";
import {
  ContentModule,
  getGlobals,
  getGraphqlSdk,
  GlobalProps,
  GraphQLResponseError
} from "@src/lib/services/server/contentful";
import type {
  ProductHomepageQuery,
  ProductHomepageContentModulesQuery
} from "@src/lib/services/server/contentful/generated/graphqlSdk";
import { logError } from "@src/lib/services/server/logger";
import Error from "next/error";
import { filterNull } from "@src/lib/utils";
import "setimmediate";
import {
  ProductLayout,
  ProductContentModules,
  ProductHeroHomepage
} from "@src/components";
import { setCacheHeaders } from "@src/lib/utils/pageCache";
import { PageCacheGroups } from "@src/lib/types/fastly";

export type ProductHomeProps = GlobalProps & {
  entry?: ProductHomepageQuery;
  contentModules?: ProductHomepageContentModulesQuery;
};

export const ProductHome: NextPage<ProductHomeProps> = ({
  entry,
  contentModules,
  ...layoutProps
}) => {
  if (!entry) {
    return <Error statusCode={500} />;
  }

  const entryItem = entry.productHomepageCollection
    ? entry.productHomepageCollection.items[0]
    : null;

  const contentModulesItem =
    contentModules && contentModules.productHomepageCollection
      ? contentModules.productHomepageCollection.items[0]
      : null;

  if (!entryItem) {
    return null;
  }

  const contentModulesItems = filterNull(
    contentModulesItem?.contentModulesCollection?.items
  );

  return (
    <ProductLayout entry={entryItem} {...layoutProps}>
      <section id="hero">
        <ProductHeroHomepage heroSlider={entryItem.heroSlider} />
      </section>
      <section id="content">
        <ProductContentModules
          contentModules={contentModulesItems as ContentModule[]}
        />
      </section>
    </ProductLayout>
  );
};

export const getServerSideProps: GetServerSideProps<
  ProductHomeProps
> = async context => {
  const { preview = false } = context;
  const props: ProductHomeProps = {};

  const graphqlSdk = getGraphqlSdk(preview);
  const productHomepageQuery = graphqlSdk.productHomepage({ preview });
  const productHomepageContentModulesQuery =
    graphqlSdk.productHomepageContentModules({
      preview
    });
  const globalsQuery = getGlobals(preview);

  Object.assign(props, await globalsQuery);

  setCacheHeaders(PageCacheGroups.HOMEPAGE, context);

  try {
    props.entry = await productHomepageQuery;
  } catch (e) {
    const error = e as GraphQLResponseError<ProductHomepageQuery>;
    if (error?.response?.data) {
      props.entry = error.response.data;
    } else {
      logError(error);
      return { props };
    }
  }

  try {
    props.contentModules = await productHomepageContentModulesQuery;
  } catch (error) {
    logError(error);
    props.contentModules = undefined;
  }

  return { props };
};

export default ProductHome;
